import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Pagination,
  Form,
} from "@themesberg/react-bootstrap";

export default ({
  data = [],
  columns = [],
  pagination = null,
  handlePagination = null,
  showIndeterminateCheckbox = false,
  handleRowSelectionChange = null,
  rowsSelections = [],
  totalRowSelection = 0,
  filterColumns = [],
  handleFilter = null,
  filterValues = null,
  lastFieldSearch = null,
  handleSortingChange = null,
  initialSorting = [],
}) => {
  let initialRowSelection = {};

  if (rowsSelections.length) {
    rowsSelections.forEach((rowSelection) => {
      initialRowSelection[rowSelection.index] = true;
    });
  }

  const [rowSelection, setRowSelection] = React.useState(initialRowSelection);
  //const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState(initialSorting)

  const dataColumns = React.useMemo(() => {
    if (showIndeterminateCheckbox) {
      return [
        {
          id: "select",
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
        },
        ...columns,
      ];
    }

    return columns;
  }, [columns, showIndeterminateCheckbox]);

  const onSortingChange = (value) => {
    setSorting(value);

    if (handleSortingChange) {
      handleSortingChange(value());
    }
  }

  const table = useReactTable({
    data,
    columns: dataColumns,
    state: {
      rowSelection,
      pagination,
      sorting,
    },
    manualPagination: true,
    pageCount: pagination.pageCount,
    onPaginationChange: (updater) => {
      // make sure updater is callable (to avoid typescript warning)
      if (typeof updater !== "function") return;

      const newPageInfo = updater(table.getState().pagination);

      handlePagination(
        newPageInfo.pageIndex + 1,
        newPageInfo.pageSize,
        filterValues,
        sorting && sorting?.length > 0 ? sorting[0] : null
      );
    },
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: (updater) => {
      // make sure updater is callable (to avoid typescript warning)
      if (typeof updater !== "function") return;

      setRowSelection(updater);
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSortingRemoval: false,
    onSortingChange: onSortingChange,
    // getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true
  });

  React.useEffect(() => {
    if (!rowSelection) return;

    if (typeof handleRowSelectionChange === "function") {
      handleRowSelectionChange(table.getSelectedRowModel().rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  return (
    <div className="p-2">
      {/* <div>
          <input
            value={globalFilter ?? ''}
            onChange={e => setGlobalFilter(e.target.value)}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search all columns..."
          />
        </div> */}
      <div className="h-2" />
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="border-bottom"
                      >
                        {header.isPlaceholder ||
                        header.id === "select" ? null : (
                        <div
                          className={
                            header.column.columnDef?.sort
                              ? 'cursor-pointer'
                              : ''
                          }
                          onClick={header.column.columnDef?.sort ? header.column.getToggleSortingHandler() : undefined}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                  ? 'Sort descending'
                                  : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.columnDef?.sort ? {
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null : undefined}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter
                                column={header.column}
                                table={table}
                                filterColumns={filterColumns}
                                setFilterValue={handleFilter}
                                filterValues={filterValues}
                                lastFieldSearch={lastFieldSearch}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          <span className="fw-normal">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                {false && showIndeterminateCheckbox && (
                  <td className="p-1">
                    <IndeterminateCheckbox
                      {...{
                        checked: table.getIsAllPageRowsSelected(),
                        indeterminate: table.getIsSomePageRowsSelected(),
                        onChange: table.getToggleAllPageRowsSelectedHandler(),
                      }}
                    />
                  </td>
                )}
                <td colSpan={20}>
                  Page Rows ({table.getRowModel().rows.length})
                </td>
              </tr>
            </tfoot>
          </Table>
        </Card.Body>
      </Card>
      <div className="h-2" />
      <Row className="flex items-center gap-2 mt-2">
        <Col md={5} className="mb-3">
          <Row>
            <Col md={5}>
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </Pagination.Prev>
                  <Pagination.Prev
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<"}
                  </Pagination.Prev>
                  <Pagination.Next
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </Pagination.Next>
                  <Pagination.Next
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </Pagination.Next>
                </Pagination>
              </Nav>
            </Col>
            <Col md={7} className="mt-2">
              <span>
                Page
                <strong>
                  {" "}
                  {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()}{" "}
                </strong>
              </span>
              <span>
                | Go to page:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  min={1}
                  max={table.getPageCount()}
                  onChange={(e) => {
                    const page = Number(e.target.value);
                    table.setPageIndex(page - 1);
                  }}
                  className="border p-1 rounded w-16"
                />
              </span>
            </Col>
          </Row>
        </Col>
        <Col md={2} className="mt-2">
          <Form.Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="text-center"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <div>
        {totalRowSelection} of {pagination.itemCount} Total Rows Selected
      </div>
    </div>
  );
};

function Filter({
  column,
  table,
  filterColumns,
  setFilterValue,
  filterValues,
  lastFieldSearch
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  if (!filterColumns.includes(column.id)) return <></>

  let value = "";

  if (typeof setFilterValue === "function") {
    if (filterValues) {
      value = filterValues[column.id] ?? "";
    }
  } else {
    value = column.getFilterValue() ?? "";
  }

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      {/* <input
        type="number"
        value={column.getFilterValue()?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={column.getFilterValue()?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      /> */}
    </div>
  ) : column.columnDef?.filter && column.columnDef?.filter === 'select'
      ? (
        <Form.Select
          value={value}
          onChange={
            (e) =>
              typeof setFilterValue === "function"
                ? setFilterValue({ id: column.id, value: e.target.value })
                : column.setFilterValue(e.target.value)
          }
          ref={inputElement => {
            // constructs a new function on each render
            if (
              inputElement &&
              lastFieldSearch &&
              lastFieldSearch === column.id
            ) {
              inputElement.focus();
            }
          }}
        >
          <option value="" selected>All</option>
          {column.columnDef.filterValues.map((filterValue) => (
            <option key={filterValue.id} value={filterValue.id}>
              {filterValue.text}
            </option>
          ))}
        </Form.Select>
      )
    : (
      <Form.Control
        type="text"
        value={value}
        onChange={
          (e) =>
            typeof setFilterValue === "function"
              ? setFilterValue({ id: column.id, value: e.target.value })
              : column.setFilterValue(e.target.value)
        }
        placeholder={`Search...`}
        className="w-36 p-1 mt-2 border-1 rounded"
        ref={inputElement => {
          // constructs a new function on each render
          if (
            inputElement &&
            lastFieldSearch &&
            lastFieldSearch === column.id
          ) {
            inputElement.focus();
          }
        }}
      />
    );
}

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = null;

  React.useEffect(() => {
    if (typeof indeterminate === "boolean" && ref?.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
