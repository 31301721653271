import api from "../index";

export const getAllCities = async (query) => {
  try {
    const response = await api.get(`/cities/${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
