import React from 'react';
import { Modal, Button, } from '@themesberg/react-bootstrap';

export const DeleteConfirmationModel = ({
    show = false,
    handleClose,
    handleYes,
    isLoading = false
}) => {
    return (
      <>
        <Modal
          show={show}
          onHide={!isLoading ? handleClose : null}
          backdrop="static"
          keyboard={false}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>You're sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the records?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => handleClose(false)}
              disabled={isLoading}
            >
              CANCEL
            </Button>
            <Button
              variant="danger"
              onClick={handleYes}
              disabled={isLoading}
            >
              {isLoading ? 'Loading…' : 'YES'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default DeleteConfirmationModel;