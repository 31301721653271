import api from '../index';

// Get
export const getCarriers = async (page, take, filter, sort) => {
  let url = "/carriers";

  if (page && take) {
    url = `${url}?pagination[page]=${page}&pagination[take]=${take}`
  }

  if (filter) {
    Object.keys(filter).forEach(item => {
      url = `${url}&filter[${item}]=${filter[item]}`
    });
  }

  if (sort) {
    url = `${url}&sort[${sort['id']}]=${sort['desc'] ? 'DESC' : 'ASC'}`
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Patch
export const putAcceptCarrier = async (id) => {
  try {
    const response = await api.put(`/carriers/${id}/accept`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
