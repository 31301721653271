import api from '../index';

// Get
export const getLoadModes = async () => {
  try {
    const response = await api.get('/load-mode');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
