import React from 'react';
import { Modal, Button, } from '@themesberg/react-bootstrap';

export const ConfirmModal = ({
    show = false,
    handleClose,
    handleYes,
    isLoading = false,
    textBody
}) => {
    return (
      <>
        <Modal
          show={show}
          onHide={!isLoading ? handleClose : null}
          backdrop="static"
          keyboard={false}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>You're sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>{textBody}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => { if (!isLoading) { handleClose(false); } } }
              disabled={isLoading}
            >
              CANCEL
            </Button>
            <Button
              variant="danger"
              onClick={handleYes}
              disabled={isLoading}
            >
              {isLoading ? 'Loading…' : 'YES'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default ConfirmModal;