import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from '@themesberg/react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { getAllCities } from "../../services/api/cities";
import { getAllEquipmentType } from "../../services/api/equipmentType";


import "./SuggestionNames.scss";

export function SuggestionNamesOrigin({
  selectOriginCity,
  labelName,
  reset = false,
  valueSelectedQuery = ''
}) {

  const [selectedQuery, setSelectedQuery] = useState(valueSelectedQuery);
  const [query, setQuery] = useState('')
  const [timer, setTimer] = useState(null);

  const [suggestionQuery, setSuggestionQuery] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const handleClick = (city) => {
    const cityName = `${city.city_city_name}, ${city.city_code_state_state_code}`
    setSelectedQuery(cityName);
    setShowSuggestion(false);
    selectOriginCity(city.city_city_id);
  }

  const handleSelectionQuery = (query) => {
    if (query) {
      setShowSuggestion(true);
      setQuery(query)
    } else {
      setShowSuggestion(false);
    }

    setSelectedQuery(query);
  }

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      if (query) {
        const data = await getAllCities(query);
        setSuggestionQuery(data);
      }
    }, 700);
    setTimer(newTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (reset) {
      setSelectedQuery('');
    }
  }, [reset]);

  useEffect(() => {
    setSelectedQuery(valueSelectedQuery);
  }, [valueSelectedQuery]);

  return (
    <>
      <Col md={6} className="mb-3">
        <Form.Group id="firstName">
          <Form.Label>{labelName}</Form.Label>
          <Form.Control
            required type="text"
            placeholder="Enter city name"
            onChange={(e) => handleSelectionQuery(e.target.value, 1)}
            value={selectedQuery}
          />
        </Form.Group>
        {showSuggestion && (
          <div className="panel panel-primary" id="result_panel">
            <div className='panel-body'>
              <ul className="list-group">
                {
                  suggestionQuery.map((item) => {
                    return (
                      <li key={item.city_city_id}
                        className="list-group-item"
                        onClick={() => handleClick(item)}
                      >
                        <i className="fa-sharp fa-solid fa-location-dot"></i>
                        <span> {item.city_city_name}, {item.city_code_state_state_code}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        )}
      </Col>
    </>
  )
}

export function SuggestionNamesDestination({
  selectDestintationCity,
  labelName,
  valueSelectedQuery = ''
}) {
  const [selectedQuery, setSelectedQuery] = useState(valueSelectedQuery);
  const [query, setQuery] = useState('')
  const [timer, setTimer] = useState(null);

  const [suggestionQuery, setSuggestionQuery] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const handleClick = (city) => {
    const cityName = `${city.city_city_name}, ${city.city_code_state_state_code}`
    setSelectedQuery(cityName);
    setShowSuggestion(false);
    selectDestintationCity(city.city_city_id);
  }

  const handleSelectionQuery = (query) => {
    if (query) {
      setShowSuggestion(true);
      setQuery(query);
    } else {
      setShowSuggestion(false);
    }

    setSelectedQuery(query);
  }

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      if (query) {
        console.log("petición... 1")
        const data = await getAllCities(query);
        setSuggestionQuery(data);
      }
    }, 700);
    setTimer(newTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setSelectedQuery(valueSelectedQuery);
  }, [valueSelectedQuery]);

  return (
    <>
      <Col md={6} className="mb-3">
        <Form.Group id="firstName">
          <Form.Label>{labelName}</Form.Label>
          <Form.Control
            required type="text"
            placeholder="Enter city name"
            onChange={(e) => handleSelectionQuery(e.target.value, 1)}
            value={selectedQuery}
          />
        </Form.Group>
        {showSuggestion && (
          <div className="panel panel-primary" id="result_panel">
            <div className='panel-body'>
              <ul className="list-group">
                {
                  suggestionQuery.map((item) => {
                    return (
                      <li key={item.city_city_id}
                        className="list-group-item"
                        onClick={() => handleClick(item)}
                      >
                        <i className="fa-sharp fa-solid fa-location-dot"></i>
                        <span> {item.city_city_name}, {item.city_code_state_state_code}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        )}
      </Col>
    </>
  )
}

export function SuggestionEquipmentType({
  selectEquipmentType,
  valueSelectedQuery = ''
}) {
  const [selectedQuery, setSelectedQuery] = useState(valueSelectedQuery);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestionQuery, setSuggestionQuery] = useState([]);
  const [equipmentType, setEquipmentType] = useState([]);

  const primerLetra = (str) => {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  const handleSelectionQuery = (query) => {
    setShowSuggestion(true);
    setSelectedQuery(query);
  }

  const handleClickEquipmentType = (equipmentName, equipmentId) => {
    selectEquipmentType(equipmentId)
    setSelectedQuery(equipmentName)
    setShowSuggestion(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllEquipmentType();
        setEquipmentType(response);
        setSuggestionQuery(response);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (equipmentType.length) {
      const data = () =>
        equipmentType.map((type) => ({
          type,
          equipment_id: type.equipment_id,
          equipment_name: primerLetra(type.equipment_name)
        })).filter((type) => type.equipment_name.includes(selectedQuery)
          | type.equipment_name.toLowerCase().includes(selectedQuery)
          | type.equipment_name.toUpperCase().includes(selectedQuery)
        )
      setSuggestionQuery(data);
    }
  }, [equipmentType, selectedQuery]);

  useEffect(() => {
    setSelectedQuery(valueSelectedQuery);
  }, [valueSelectedQuery]);

  return (
    <Col md={6} className="mb-3">
      <Form.Group id="firstName">
        <Form.Label>{"Equipment Type"}</Form.Label>
        <Form.Control
          required type="text"
          placeholder="Name of the type of equipment"
          onFocus={() => setShowSuggestion(true)}
          onChange={(e) => handleSelectionQuery(e.target.value)}
          value={selectedQuery}
        />
      </Form.Group>
      {showSuggestion && (
        <div className="panel panel-primary" id="result_panel">
          <div className='panel-body'>
            <ul className="list-group">
              {
                suggestionQuery.map((item) => {
                  return (
                    <li key={item.equipment_id}
                      className="list-group-item"
                      onClick={() => handleClickEquipmentType(item.equipment_name, item.equipment_id)}
                    >
                      <i className="fa-sharp fa-solid fa-location-dot"></i>
                      <span> {item.equipment_name}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      )}
    </Col >
  )
}

export function SuggestionLoadsByCity({
  selectCityBySuggestion
}) {
  const [timer, setTimer] = useState(null);

  const handleSelectionQuery = (query) => {

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      selectCityBySuggestion(query);
    }, 700)

    setTimer(newTimer);
  }

  return (
    <Form className="mb-4">
      <Form.Group>
        <Form.Label>FIND TRUCK LOADS FROM</Form.Label>
        <InputGroup>
          <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
          <Form.Control
            required type="text"
            placeholder="Enter city name"
            onChange={(e) => handleSelectionQuery(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
    </Form>
  );
}