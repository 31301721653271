
export const Routes = {
  // pages for GLG
  DashboardOverview: { path: "/" },
  // Load
  MyLoads: { path: "/loads/my-loads" },
  NewLoads: { path: "/loads/new-loads" },
  EditLoad: { path: "/loads/:id" },
  // Tracking
  TrackLoad: { path: "/tracking/track-load" },
  LoadsTracked: { path: "/tracking/loads-tracked" },
  // Domain Carrier
  ListCarriers : { path: "/carriers/list" },
  NewCarriers : { path: "/carriers/new-carrier" },
  
  //Pages for GLG carriers
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  NotFound: { path: "/404" },

  //pages for Carriers
  DashboardCarriers: { path: '/loadboard' }
};