import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, Link } from "react-router-dom";

import "./DashboardCarriers.css"

import {
  Col,
  Row,
  Container,
  Image,
  Dropdown,
  Card,
  Button,
  Accordion,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Navbar } from "@themesberg/react-bootstrap";
import { faSignOutAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons";

import Brand from "../../assets/img/brand/logo-white.svg";
import Profile3 from "../../assets/img/team/profile-picture-4.jpg";
import iconA from "../../assets/img/icons/icons8-marker-a-50 (2).png";
import iconB from "../../assets/img/icons/icons8-marker-b-50 (1).png";

import Table from "../../components/Tables/Table";
import { getLoads, selectLoad } from "../../services/api/loads";

import { Routes } from "../../routes";

import { ModalForCarriersInfo } from "./Modal/Modal";
import levelAccessConstants from "../../utils/constants/levelAccess.constants";

export default () => {
  const history = useHistory();

  const [levelId, setLevelId] = useState(null);
  const [nameAuth, setNameAuth] = useState("");
  const [modalData, setModalData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterValues, setFilterValues] = useState(null);
  const [timer, setTimer] = useState(null);
  const [lastFieldSearch, setLastFieldSearch] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(null);
  const navRef = useRef(null)

  const [isMovil, setIsMovil] = useState(() => {
    return window.innerWidth >= 992 ? true : false;
  });

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  const handleClick = () => {
    if (navRef.current) {
      setIsCollapsed(false)
    }
  }

  const columns = [
    {
      header: "#",
      footer: (props) => props.column.id,
      accessorKey: "item.load_id",
      cell: (info) => info.getValue(),
      sort: true
    },
    {
      header: "Origin",
      footer: (props) => props.column.id,
      accessorKey: "origin_city_and_state",
      cell: (info) => info.getValue(),
    },
    {
      header: "Destination",
      footer: (props) => props.column.id,
      accessorKey: "destination_city_and_state",
      cell: (info) => info.getValue(),
    },
    {
      header: "Loading condition",
      footer: (props) => props.column.id,
      accessorKey: "item.equipment_details",
      cell: (info) => `${info.getValue()[0].toLocaleUpperCase()}${info.getValue().substr(1)}`,
      filter: 'select',
      filterValues: [
        {
          id: 'Full',
          text: 'Full'
        },
        {
          id: 'Partial',
          text: 'Partial'
        }
      ]
    },
    {
      header: "Pickup",
      footer: (props) => props.column.id,
      accessorKey: "item.load_pickup",
      cell: (info) => info.getValue(),
    },
    {
      header: "Miles",
      footer: (props) => props.column.id,
      accessorKey: "item.miles",
      cell: (info) => info.getValue(),
    },
    {
      header: "Equipment",
      footer: (props) => props.column.id,
      accessorKey: "item.load_equipment_type.equipment_name",
      cell: (info) => info.getValue(),
    },
    {
      header: "Status",
      footer: (props) => props.column.id,
      accessorKey: "item.load_status",
      cell: (info) => {
        const statusVariant =
          info.row.original.item.load_status === "active"
            ? "success"
            : "danger";

        return (
          <span className={`text-${statusVariant}`}>{info.getValue()}</span>
        );
      },
    },
    {
      header: "Action",
      cell: (info) => {
        return levelId ? (
          <Button
            variant="primary"
            onClick={() => openModal(info.row.original)}
          >
            View more Details
          </Button>
        ) : (
          <Card.Link
            as={Link}
            to={Routes.Signin.path}
            className="fw-normal text-black"
          >
            {`Sign in  for Details`}
          </Card.Link>
        );
      },
    },
  ];
  const filterColumns = [
    'origin_city_and_state',
    'destination_city_and_state',
    'item_load_equipment_type.equipment_name',
    'item_equipment_details'
  ];
  const [initialSorting, setInitialSorting] = useState([
    {
      desc: true,
      id: "item_load_id"
    }
  ]);

  const openModal = (rowData) => {
    setModalData(rowData);
  };

  const setDataList = (response) => {
    setList(response.data);
    setPagination({
      pageCount: response.pagination.pageCount,
      pageSize: response.pagination.take,
      pageIndex: response.pagination.page - 1,
      itemCount: response.pagination.itemCount,
    });
  };

  const getPaginationList = useCallback(async (
    numberPage,
    numberTake,
    filter,
    sort
  ) => {
    setLoading(true);
    // Transform structure
    if (filter) {
      filter = {
        equipment_name: filter['item_load_equipment_type.equipment_name'] ?? '',
        destination: filter['destination_city_and_state'] ?? '',
        origin: filter['origin_city_and_state'] ?? '',
        equipment_loading_condition: filter['item_equipment_details'] ?? ''
      }
    }

    const data = await getLoads(numberPage, numberTake, filter, sort);

    setDataList(data);

    setLoading(false);
  }, []);

  const signOff = () => {
    window.localStorage.removeItem("authorization");
    window.localStorage.removeItem("user");
    history.push("sign-in");
  };

  const TableForMovil = (props) => {
    const { defaultKey, data = [], className = "show-test" } = props;
    const history = useHistory();

    const AccordionItem = (item) => {
      const { className, destination_city_and_state, origin_city_and_state } =
        item;

      const statusVariant =
        item.item.load_status === "active" ? "success" : "danger";

      const handlerClickAccordionItem = () => {
        if (!levelId) {
          history.push("/sign-in");
        }

        return;
      };

      return (
        <Accordion.Item
          eventKey={item.item.load_id}
          onClick={handlerClickAccordionItem}
        >
          <Accordion.Button
            variant="link"
            className={!levelId ? className : " "}
          >
            <div className="card__title">
              <div className="card__header">
                <h2 className="title__miles">{item.item.miles}</h2>
                <h3 className={`title__miles text-${statusVariant}`}>
                  {item.item.load_status}
                </h3>
              </div>
              <div className="title__destination">
                <Image className="card__icon" src={iconA} />
                <div className="wrapperInfo">
                  <p className="sub_title">City origin</p>
                  <h4>{origin_city_and_state}</h4>
                  <p>{item.item.load_pickup}</p>
                </div>
              </div>
              <div className="title__destination">
                <div className="title__destination">
                  <Image className="card__icon" src={iconB} />
                  <div className="wrapperInfo">
                    <p className="sub_title">City destination</p>
                    <h4>{destination_city_and_state}</h4>
                  </div>
                </div>
              </div>
              <div className="title__details">
                <p>{item.item.miles}</p>
                <span className="divider">|</span>
                <p>{item.item.load_length} lbs</p>
                <span className="divider">|</span>
                <p>{item.item.load_weight} fts</p>
                <span className="divider">|</span>
                <p>{item.item.equipment_details}</p>
                <span className="divider">|</span>
                <p>{item.item.load_equipment_type.equipment_name}</p>
              </div>
              <div>
                {!levelId && (
                  <Card.Link
                    as={Link}
                    to={Routes.Signin.path}
                    className="fw-normal text-black login__message"
                  >
                    {` Sign in  for Details  `}
                  </Card.Link>
                )}
              </div>
            </div>
          </Accordion.Button>
          <Accordion.Body>
            <Card.Body className="py-2 px-0">
              <div className="description__details">
                <div className="description__equipment">
                  <h3 className="equipment__header">EQUIPMENT</h3>
                  <p>Load: {item.item.load_equipment_type.equipment_name}</p>
                  <p>Truck: {item.item.equipment_details}</p>
                  <p>Weight: {item.item.load_length} lbs</p>
                  <p>Length: {item.item.load_weight} fts</p>
                  <h3 className="equipment__header mt-2">COMMENTS</h3>
                  <p>{item.item.additional_information}</p>
                </div>
                <div className="date__details mt-2">
                  <h3 className="equipment__header">DATE DETAILS</h3>
                  <p>Pickup: {item.item.load_pickup}</p>
                  <p>Pickup hours: {item.item.load_pickup_hours}</p>
                  <p>Drop off hours: {item.item.load_pickup_drop_off_hours}</p>
                </div>
                {!item.item.takenByCarrier && <Button
                  variant="primary"
                  size="sm"
                  className="mt-4"
                  onClick={() => handleSelectLoad(item.item.load_id)}
                >
                  Select load
                </Button>}
              </div>
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      );
    };

    return (
      <Accordion defaultActiveKey={defaultKey}>
        {data.map((d) => (
          <AccordionItem
            key={`accordion-${d.item.load_id}`}
            {...d}
            className={className}
          />
        ))}
      </Accordion>
    );
  };

  const handleFilter = (filter) => {
    const isFilterText = columns.some(
      column =>
        column?.accessorKey?.replace('.', '_') === filter.id &&
        column?.filter !== 'select'
    );

    if (timer && isFilterText) {
      clearTimeout(timer);
    }

    setLastFieldSearch(filter.id);

    const newFilter = {
      ...filterValues,
      [filter.id]: filter.value
    };
    setFilterValues(newFilter);

    if (isFilterText) {
      const newTimer = setTimeout(async () => {
        getPaginationList(
          1,
          10,
          newFilter
        );
      }, 700);
      setTimer(newTimer);

      return;
    }

    getPaginationList(
      1,
      10,
      newFilter
    );
  }

  const handleSortingChange = (value) => {
    setInitialSorting(value);
    getPaginationList(
      1,
      10,
      filterValues,
      value[0]
    );
  }

  const handleSelectLoad = async (currentId) => {
    const id = currentId ?? modalData.item.load_id;
    const response = await selectLoad(id);

    if (response === true) {
      const newList = list.map(record => {
        if (record.item.load_id === id) {
          record.item.takenByCarrier = true;
        }

        return record;
      });

      setList(newList);
      setModalData(false);
    }
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    if (user) {
      setLevelId(user.level_access);
      setNameAuth(`${user.user_first_name} ${user.user_last_name}`);

      const levelAccessAdmin = levelAccessConstants.ADMIN;

      if (user.level_access === levelAccessAdmin) {
        history.push("/");
      }
    }
  }, [history]);

  useEffect(() => {
    getPaginationList(1, 10);
  }, [getPaginationList]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        expanded={isCollapsed}
        onToggle={handleToggle}
        className="navbar-transparent navbar-theme-primary mb-5 p-4"
        style={{ background: "#041423" }}
      >
        <Navbar.Brand href="/" className="me-lg-3">
          <Image src={Brand} style={{ height: "40px" }} />
        </Navbar.Brand>
        <Navbar.Collapse
          id="navbar-default-primary"
          ref={navRef}
          className="collapse-fullscreen w-100 justify-content-end"
        >
          <Nav className="navbar-nav-hover align-items-lg-center collapse-fullscreen-container">
            {!levelId &&
              <div className="d-flex justify-content-between navbar__header align-items-center">
                <Nav.Link href="/" className="nav__link">Log in as carriers</Nav.Link>
                <button type="button" className="d-block d-sm-block d-md-block d-lg-none d-xl-none btn-close" onClick={handleClick}></button>
              </div>
            }
            {levelId && (
              <Dropdown
                as={Nav.Item}
                className="d-flex justify-content-between dropdown__header"
              >
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image
                      src={Profile3}
                      className="user-avatar md-avatar rounded-circle"
                    />
                    <button type="button" className="d-block d-sm-block d-md-block d-lg-none d-xl-none btn-close" onClick={handleClick}></button>
                    <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">
                        {nameAuth}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                {
                  isMovil ? (
                    <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                      <Dropdown.Item className="fw-bold" onClick={() => signOff()}>
                        <FontAwesomeIcon
                          icon={faSignOutAlt}
                          className="text-danger me-2"
                        />{" "}
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  ) : <button className="logout__button" onClick={() => signOff()} >Logout</button>
                }
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="navbar-default-primary" />
      </Navbar>
      <Container fluid>
        <Row>
          <Col md={6} xs={12}>
            <h5>Truck loads found in real time</h5>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Row>
              <Col xs={12} xl={12} className="mb-4">
                <Row>
                  <Col xs={12} className="mb-4">
                    {loading ? (
                      <h1>Loading...</h1>
                    ) : isMobile ? (
                      <TableForMovil defaultKey="panel-1" data={list} />
                    ) : (
                      <Table
                        columns={columns}
                        data={list}
                        pagination={pagination}
                        handlePagination={getPaginationList}
                        filterColumns={filterColumns}
                        handleFilter={handleFilter}
                        filterValues={filterValues}
                        lastFieldSearch={lastFieldSearch}
                        handleSortingChange={handleSortingChange}
                        initialSorting={initialSorting}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {modalData && (
        <ModalForCarriersInfo
          show={!!modalData}
          onHide={() => setModalData(false)}
          handleYes={handleSelectLoad}
          data={modalData}
        />
      )}
    </>
  );
};
