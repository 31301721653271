import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";

// pages for GLG
import DashboardOverview from "./dashboard/DashboardOverview";
import MyLoads from "./loads/MyLoads";
import NewLoads from './loads/NewLoads';

//pages Carriers
import DashboardCarriers from "../pages/dashboard/DashboardCarriers";

import ListCarriers from "../pages/carriers/ListCarriers";
import NewCarriers from "../pages/carriers/NewCarriers";

import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import NotFoundPage from "../pages/NotFound";

// components
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import GlobalStyle from '../globalStyles';

import { getMe } from "../services/api/auth";
import levelAccessConstants from '../utils/constants/levelAccess.constants';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, titleSection, ...rest }) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [levelAccess, setLevelAccess] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  const validateUser = useCallback(async () => {
    try {
      const response = await getMe();

      const LEVEL_ACCESS_CARRIER_ID = levelAccessConstants.CARRIER;
      setLevelAccess(response.user.level_access);

      if (response.user.level_access.level_id === LEVEL_ACCESS_CARRIER_ID) {
        history.push('/loadboard');

        return;
      }
    } catch (e) {
      history.push('/sign-in');
    }
  }, [history]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return (
    <Route {...rest} render={props => (
      <>
        <GlobalStyle />
        <Preloader show={loaded ? false : true} />
        <Sidebar levelAccess={levelAccess} />

        <main className="content">
          <Navbar titleSection={titleSection} />
          <Component {...props} levelAccess={levelAccess} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

const RouteWithoutSidebar = ({ component: Component, titleSection, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (
      <>
        <GlobalStyle />
        <Preloader show={loaded ? false : true} />
        <main>
          <Component {...props} />
        </main>
      </>
    )}
    />

  )
}

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} titleSection={"Overview"} component={DashboardOverview} />

    {/* Load */}
    <RouteWithSidebar exact path={Routes.MyLoads.path} titleSection={"My loads"} component={MyLoads} />
    <RouteWithSidebar exact path={Routes.NewLoads.path} titleSection={"New load"} component={NewLoads} />
    <RouteWithSidebar exact path={Routes.EditLoad.path} titleSection={"Edit load"} component={NewLoads} />

    <RouteWithSidebar exact path={Routes.ListCarriers.path} titleSection={"List carrier"} component={ListCarriers} />
    <RouteWithSidebar exact path={Routes.NewCarriers.path} titleSection={"New carrier"} component={NewCarriers} />

    {/* pages for carriers */}
    <RouteWithoutSidebar exact path={Routes.DashboardCarriers.path} titleSection={"Load board"} component={DashboardCarriers} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
