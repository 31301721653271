import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapPin,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Modal, Row } from "@themesberg/react-bootstrap";
import "./Modal.scss";

export const ModalForCarriersInfo = (props) => {
  return (
    <Modal
      size="xl"
      className="mi-modal"
      as={Modal.Dialog}
      animation
      centered
      show={props.show}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          {props.data.origin_city_and_state}
          <FontAwesomeIcon icon={faLongArrowAltRight} className="mx-2" />
          {props.data.destination_city_and_state}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <h4>Trip</h4>
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon icon={faMapPin} className="mx-2" />
              <h5>{props.data.origin_city_and_state}</h5>
            </div>
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon icon={faMapPin} className="mx-2" />
              <h5>{props.data.destination_city_and_state}</h5>
            </div>
            <p>Miles: {props.data.item.miles}</p>
            <p>
              {" "}
              <strong>Load pickup: </strong> {props.data.item.load_pickup}
            </p>
            <p>
              {" "}
              <strong>Pickup hours: </strong>{" "}
              {props.data.item.load_pickup_hours}
            </p>
            <p>
              Pickup drop off hours:{" "}
              {props.data.item.load_pickup_drop_off_hours}
            </p>
            <hr />
            <h4>Equipment</h4>
            <p>
              {" "}
              <strong>Equipment type: </strong>
              {props.data.item.load_equipment_type.equipment_name}
            </p>
            <p>
              {" "}
              <strong>Equipment details: </strong>{" "}
              {props.data.item.equipment_details}
            </p>
            <p>
              {" "}
              <strong>Weight: </strong> {props.data.item.load_weight}
            </p>
            <p>Lenght: {props.data.item.load_length}</p>
            <p>
              Additional information: {props.data.item.additional_information}
            </p>
          </Col>
          <Col md={4}>
            <h4>Rate</h4>
            <p>{props.data.item.load_rate} $</p>
          </Col>
          <Col md={4}>
            <h4>Posting Contact Information </h4>
            <p>User: {props.data.item.createdBy.user_first_name} {props.data.item.createdBy.user_last_name}</p>
            <p>Email: {props.data.item.createdBy.user_email}</p>
            <p>Contact number: {props.data.item.createdBy.user_detail.contact_number}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!props.data.item.takenByCarrier && <Button variant="primary" onClick={() => props.handleYes(props.data.item.load_id)}>
          Select load
        </Button>}
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
