
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Routes } from '../routes';
import BgImage from '../assets/img/illustrations/signup.jpg';

import { loginUser } from '../services/api/auth';
import levelAccessConstants from '../utils/constants/levelAccess.constants';

export default () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      user_email: userEmail,
      password: userPassword
    };

    try {
      const response = await loginUser(userData);
      if (response.token) {
        window.localStorage.setItem('authorization', response.token);
        window.localStorage.setItem('user', JSON.stringify(response.user));

        const LEVEL_ACCESS_CARRIER_ID = levelAccessConstants.CARRIER;

        if (response.user.level_access === LEVEL_ACCESS_CARRIER_ID) {
          history.push('/loadboard')

          return;
        }

        history.push('/')
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const handleChangeUserEmail = (e) => {
    const value = e.target.value;
    setUserEmail(value)
  }

  const handleChangeUserPassword = (e) => {
    const value = e.target.value;
    setUserPassword(value)
  }

  return (
    <main style={{
      backgroundImage: `url(${BgImage})`, backgroundSize: "cover", backgroundPosition: 'center', minHeight: '100vh', // Ajusta la altura mínima según tus necesidades
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">It's good to see you again</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        value={userEmail}
                        onChange={handleChangeUserEmail}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Password"
                          value={userPassword}
                          onChange={handleChangeUserPassword}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Register here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
