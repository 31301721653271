import api from "../index";

import { toast } from 'react-toastify';


export const getMe = async () => {
  try {
    const response = await api.get('/auth/user');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const loginUser = async (userData) => {
  try {
    const response = await api.post('/auth/signin', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const signupUser = async (userData) => {
  try {
    const response = await api.post('/auth/signup', userData);
    return response.data;
  } catch (error) {
    console.log('Hola Mundo');
    throw error;
  }
}

export const getUserQuestions = async () => {
  try {
    const response = await api.get('user-question')
    return response;
  } catch (error) {
    throw error;
  }
}

export const createNewCarrier = async (
  firstName,
  lastName,
  email,
  phone,
  address,
  number,
  zip,
  userQuestions,
  cityId,
  password,
  confirmPassword,
  carrierDocument,
  carrierDocumentValue,
  companyName
) => {
  try {
    await api.post('auth/signup',{
      user_first_name: firstName,
      user_last_name: lastName,
      user_email:email,
      password:password,
      confirmPassword:confirmPassword,
      contact_number: phone,
      address:address,
      number:number,
      zip:zip,
      city_id:cityId,
      carrier_type:carrierDocument,
      carrier_type_value:carrierDocumentValue,
      company_name:companyName,
      userQuestions
    });

    toast.success('Carrier created successfully');

    return true;
  } catch (error) {
    let message = 'Unknown internal error.';

    if (error.response?.data?.message) {
      message = error.response.data.message;
    } else if (
      error.response?.data?.errors &&
      error.response?.data?.errors?.length
    ) {
      message = error.response.data.errors[0]?.msg;
    }

    toast.error(message);

    return false;
  }
}