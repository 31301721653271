import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";

import { SuggestionNamesOrigin } from "../../../components/suggestionNames/SuggestionNames";
import { getUserQuestions, createNewCarrier } from "../../../services/api/auth";

export default ({
  children,
  setRegisterCompleted = () => {}
}) => {
  const [validated, setValidated] = useState(false);
  const [cityId, setCityId] = useState(null);
  const [userQuestions, setUserQuestions] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [zip, setZip] = useState("");
  const [userAnswers, setUserAnswers] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [carrierDocument, setCarrierDocument] = useState("");
  const [carrierDocumentValue, setCarrierDocumentValue] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [resetSuggestionNamesOrigin, setResetSuggestionNamesOrigin] = useState(false);

  const getCarriersQuestionsFromBackend = () => {
    getUserQuestions().then((response) => {
      setUserQuestions(response.data);
    });
  };

  const handleUserAnswersChange = (questionId, value) => {
    setUserAnswers((prevAnswer) => ({
      ...prevAnswer,
      [questionId]: value,
    }));
  };

  const handleSelectedCarrierDocument = (event) => {
    setCarrierDocument(event.target.value);
  };

  useEffect(() => {
    const initialAnswer = {};
    userQuestions.forEach((userQuestion) => {
      initialAnswer[userQuestion.question_id] = "";
    });
    setUserAnswers(initialAnswer);
  }, [userQuestions]);

  useEffect(() => {
    getCarriersQuestionsFromBackend();
  }, []);

  const handleCity = (cityId) => {
    setCityId(cityId);
  };

  const prepareAnswersForBackend = () => {
    const userQuestionsWithAnswer = Object.entries(userAnswers).map(
      ([id, answer]) => ({
        id,
        answer,
      })
    );

    return userQuestionsWithAnswer;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);

      return;
    }

    const answers = prepareAnswersForBackend();

    const success = await createNewCarrier(
      firstName,
      lastName,
      email,
      phone,
      address,
      number,
      zip,
      answers,
      cityId,
      password,
      confirmPassword,
      carrierDocument,
      carrierDocumentValue,
      companyName
    );

    if (success) {
      setRegisterCompleted(true);
      form.reset();

      // Limpiar el campo de City
      setResetSuggestionNamesOrigin(true);
      setTimeout(() => {
        setResetSuggestionNamesOrigin(false);
      }, 1000);
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">General information</h5>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your name"
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="legaName">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Also your last name"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="name@company.com"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="+12-345 678 910"
                  onChange={(event) => setPhone(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={6}>
              <Form.Group id="password" className="mb-4">
                <Form.Label>Your Password</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    required
                    type="password"
                    name="password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group id="confirmPassword" className="mb-4">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    required
                    type="password"
                    name="confirmPassword"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <h5 className="my-4">Address</h5>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your home address"
                  onChange={(event) => setAddress(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Number</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="No."
                  onChange={(event) => setNumber(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <SuggestionNamesOrigin
              selectOriginCity={handleCity}
              labelName={"City"}
              reset={resetSuggestionNamesOrigin}
            />
            <Col sm={6}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  placeholder="ZIP"
                  onChange={(event) => setZip(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5 className="my-4">Carrier information</h5>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="carrierDocument">
                <Form.Label>MC or DOT</Form.Label>
                <Form.Select
                  required
                  onChange={handleSelectedCarrierDocument}
                  aria-label="Select option"
                >
                  <option value="" selected>
                    Select option
                  </option>
                  <option value="MC">MC</option>
                  <option value="DOT">DOT</option>
                </Form.Select>
              </Form.Group>
              {carrierDocument && (
                <Form.Group>
                  <Form.Label>Enter your {carrierDocument} number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`Enter ${carrierDocument} number`}
                    onChange={(event) =>
                      setCarrierDocumentValue(event.target.value)
                    }
                    required
                  />
                </Form.Group>
              )}
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="company-name">
                <Form.Label>Company name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your company name"
                  onChange={(event) => setCompanyName(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mt-4">
              <Form.Group id="questions">
                {userQuestions.map((userQuestion) => {
                  return (
                    <React.Fragment key={userQuestion.question_id}>
                      <Form.Label className="mt-4">
                        {userQuestion.name}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your answer"
                        onChange={(event) =>
                          handleUserAnswersChange(
                            userQuestion.question_id,
                            event.target.value
                          )
                        }
                      />
                    </React.Fragment>
                  );
                })}
              </Form.Group>
            </Col>
          </Row>

          {!children && <div className="mt-3">
            <Button variant="primary" type="submit">
              Save All
            </Button>
          </div>}
  
          {children}
        </Form>
      </Card.Body>
    </Card>
  );
};
