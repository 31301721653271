import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';

import { SuggestionNamesOrigin, SuggestionNamesDestination, SuggestionEquipmentType } from "./suggestionNames/SuggestionNames";
import { createdNewLoad, getLoad, editLoad } from "../services/api/loads";
import { getLoadModes } from "../services/api/load-modes";

export const ShipmentDetails = ({ id }) => {
  const [cityOriginId, setCityOriginId] = useState(null);
  const [cityDestinationId, setCityDestinationId] = useState(null);
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [dropOffTime, setDropOffTime] = useState("");
  const [equipmentType, setEquipmentType] = useState('');
  const [lenght, setLenght] = useState('');
  const [weight, setWeight] = useState('');
  const [comments, setComments] = useState('');
  const [equipmentSize, setEquipmentSize] = useState('');
  const [rate, setRate] = useState('');
  const [validated, setValidated] = useState(false);
  const [loadModes, setLoadModes] = useState([]);
  const [loadMode, setLoadMode] = useState("");
  const [equipmentTypeName, setEquipmentTypeName] = useState("");
  const [cityOriginName, setCityOriginName] = useState("");
  const [cityDestinationName, setCityDestinationName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendLoad = async () => {
    setIsLoading(true);
    let success = null;

    const data = {
      cityOriginId,
      cityDestinationId,
      pickupDate,
      pickupTime,
      dropOffTime,
      equipmentSize,
      equipmentType,
      lenght,
      weight,
      comments,
      rate,
      loadMode
    };

    try {
      if (id > 0) {
        data.id = id;
        success = await editLoad(data);
      } else {
        success = await createdNewLoad(data);
      }
    } finally {
      setIsLoading(false);
    }

    return success;
  }

  const handleSelectionOrigin = (cityOriginId) => {
    setCityOriginId(cityOriginId);
  }

  const handleSelectionDestination = (cityDestinationId) => {
    setCityDestinationId(cityDestinationId);
  }

  const handleSelectionType = (equipmentTypeId) => {
    setEquipmentType(equipmentTypeId);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const success = await sendLoad();

      if (
        success &&
        !id
      ) {
        setPickupDate("")
        setPickupTime("")
        setDropOffTime("")
        setEquipmentType("")
        setLenght("")
        setWeight("")
        setComments("")
        setEquipmentSize("")
        setRate("")
        setLoadMode("")
        setValidated(false);
        setEquipmentTypeName("");
        setCityDestinationId("");
        setCityDestinationName("");
        setCityOriginId("");
        setCityOriginName("");
      }
    }
  };

  const primerLetra = (str) => {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  const initialRequest = useCallback(() => {
    getLoadModes()
      .then((response) => {
        setLoadModes(response);
      });

    if (id && id > 0) {
      setIsLoading(true);
      getLoad(id)
        .then((response) => {
          const data = response.data;
          setCityOriginName(`${data.load_origin.city_name}, ${data.load_origin.state.state_code}`);
          handleSelectionOrigin(data.load_origin.city_id);
          setCityDestinationName(`${data.load_destination.city_name}, ${data.load_destination.state.state_code}`);
          handleSelectionDestination(data.load_destination.city_id);

          const loadPickup = data.load_pickup;
          setPickupDate(loadPickup);

          setPickupTime("");
          setDropOffTime("");

          if (data.load_pickup_hours) {
            setPickupTime(`${loadPickup} ${data.load_pickup_hours}`);
          }

          if (data.load_pickup_drop_off_hours) {
            setDropOffTime(`${loadPickup} ${data.load_pickup_drop_off_hours}`);
          }

          setEquipmentSize(data.equipment_details);
          setLoadMode(data.load_mode.id);

          const equipmentName = primerLetra(data.load_equipment_type.equipment_name);
          setEquipmentTypeName(equipmentName);
          handleSelectionType(data.load_equipment_type.equipment_id);
          setRate(data.load_rate);
          setLenght(data.load_length);
          setWeight(data.load_weight);
          setComments(data.additional_information);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    initialRequest();
  }, [initialRequest]);

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Location and Timing</h5>
        <Form
          noValidate validated={validated} onSubmit={handleSubmit}
        >
          <Row>
            <SuggestionNamesOrigin
              selectOriginCity={handleSelectionOrigin}
              labelName={"Name of the city of origin"}
              reset={false}
              valueSelectedQuery={cityOriginName}
            />
            <SuggestionNamesDestination
              selectDestintationCity={handleSelectionDestination}
              labelName={"Name of the destination city"}
              valueSelectedQuery={cityDestinationName}
            />
          </Row>
          <Row className="align-items-center">
            <Col md={3} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Pickup</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={setPickupDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={pickupDate ? moment(pickupDate).format("MM/DD/YYYY") : ""}
                        placeholder="mm/dd/yyyy"
                        onFocus={openCalendar} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Pickup Hours</Form.Label>
                <Datetime
                  dateFormat=""
                  timeFormat={true}
                  onChange={setPickupTime}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faClock} /></InputGroup.Text>
                      <Form.Control
                        onChange={setPickupDate}
                        required
                        type="text"
                        value={pickupTime ? moment(pickupTime).format("hh:mm A") : "0:00"}
                        placeholder="hh:mm"
                        onFocus={openCalendar} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Drop Off Hours</Form.Label>
                <Datetime
                  dateFormat=""
                  timeFormat={true}
                  onChange={setDropOffTime}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faClock} /></InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={dropOffTime ? moment(dropOffTime).format("hh:mm A") : "0:00"}
                        placeholder="hh:mm"
                        onFocus={openCalendar} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
          </Row>
          <h5 className="mt-4 mb-4">Equipment Details</h5>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={3} className="mb-3">
                <Form.Label>Loading condition</Form.Label>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      label="Full"
                      name="group1"
                      type="radio"
                      id="inline-radio"
                      onChange={() => setEquipmentSize('full')}
                      value={equipmentSize}
                      checked={equipmentSize === 'full'}
                      required
                    />
                    <Form.Check
                      inline
                      label="Partial"
                      name="group1"
                      type="radio"
                      id="inline-radio"
                      onChange={() => setEquipmentSize('partial')}
                      checked={equipmentSize === 'partial'}
                      value={equipmentSize}
                      required
                    />
                  </div>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="loadRate">
                  <Form.Label>Select equipment</Form.Label>
                  <Form.Select
                    className="text-center"
                    required
                    onChange={(e) => {
                      setLoadMode(e.target.value);
                    }}
                    value={loadMode}
                  >
                    <option value="" disabled selected>Equipment</option>
                    {loadModes.map((itemMode) => (
                      <option key={itemMode.id} value={itemMode.id}>
                        {itemMode.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              </Row>
            </Col>
            <SuggestionEquipmentType selectEquipmentType={handleSelectionType} valueSelectedQuery={equipmentTypeName} />
            <Col md={2} className="mb-3">
              <Form.Group id="loadRate">
                <Form.Label>Rate</Form.Label>
                <Form.Control required type="number" min="0" value={rate} placeholder="Enter rate" onChange={(event) => setRate(event.target.value)} />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Length (ft)</Form.Label>
                <Form.Control required type="number" value={lenght} placeholder="Enter length" onChange={(event) => setLenght(event.target.value)} />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Weight (lbs)</Form.Label>
                <Form.Control required type="number" value={weight} placeholder="Enter weight" onChange={(event) => setWeight(event.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <h5 className="mt-4 mb-4">Additional Information</h5>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control placeholder="Comments" as="textarea" rows={3} value={comments} onChange={(event) => setComments(event.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3 d-flex justify-content-end">
              <Button
                variant="info"
                className="m-1"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && <>LOADING…</>}
                {!isLoading && <>{id > 0 ? 'UPDATE' : 'POST'} A LOAD</>}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card >
  )
}
