import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem('authorization')}`
  },
});

/**
 * Request interceptor for Axios HTTP client.
 *
 * This interceptor automatically adds an Authorization header to every outgoing HTTP
 * request made using the Axios instance. It retrieves an authentication token from the
 * browser's local storage and includes it in the request headers. This is essential for
 * making authenticated requests to a server or API that requires a bearer token for
 * authorization.
 *
 * The interceptor first attempts to retrieve the 'authorization' token from local storage.
 * If the token exists, it constructs an Authorization header in the format 'Bearer <token>'
 * and appends it to the request headers. This ensures that all requests sent using this
 * Axios instance are authenticated, provided that a valid token is available in local storage.
 *
 * Usage:
 * - This interceptor should be added to your Axios instance configuration if your application
 *   communicates with APIs that require bearer token authentication.
 * - Ensure that the 'authorization' token is stored in the browser's local storage whenever
 *   a user logs in or refreshes their authentication token.
 */
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('authorization'); // Obtiene el token del almacenamiento local
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;