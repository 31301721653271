import React, { useState } from "react";

import {
  Card,
  Button,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { Routes } from "../routes";
import BgImage from "../assets/img/illustrations/signup.jpg";

import CreateForm from "./carriers/components/CreateForm";

export default () => {
  const [registerCompleted, setRegisterCompleted] = useState(false);

  return (
    <main
      style={{
        backgroundImage: `url(${BgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh", // Ajusta la altura mínima según tus necesidades
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <CreateForm
          setRegisterCompleted={setRegisterCompleted}
        >
          {!registerCompleted ? (
            <div className="mt-3">
              <Button variant="primary" type="submit">
                Save All
              </Button>
            </div>
          ) : (
            <div className="mt-4">
              <span>We are almost done with the registration,</span>
              <a href="https://haulforglg.rmissecure.com/">
                <strong> continue here</strong>
              </a>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center mt-4">
            <span className="fw-normal">
              Already have an account?
              <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                {` Login here `}
              </Card.Link>
            </span>
          </div>
        </CreateForm>
      </section>
    </main>
  );
};
